import React from 'react'

const Footer = () => {
  return (
    <footer id='footer' role='contentinfo'>
      <a href='mailto:whddhks1121@naver.com' rel='noopenner noreferrer'>
        whddhks1121@naver.com
      </a>
    </footer>
  )
}

export default Footer